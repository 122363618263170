import moment from "moment";
import Utils from "../../../utility";
import axios from "axios";
export const gets2sList = () => {
  return (dispatch, getState) => {
    const { order, sortField, fromStore, toStore, offset, take, search } =
      getState().storeToStoreReducer;
    if (!(fromStore && fromStore)) return;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.s2s,
      `?order=${order}&offset=${offset}&take=${take}&search=${search}&fromStore=${
        fromStore ? fromStore : ""
      }&toStore=${toStore ? toStore : ""}`,
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.STORE_TO_STORE,
          payload: {
            storeToStore: respData.data.stores,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.s2s} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const gets2sListForExport = (csvDownload) => {
  return (dispatch, getState) => {
    const { order, toDate, fromStore, toStore, offset, take, fromDate } =
      getState().storeToStoreReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.s2s,
      `?order=${order}&offset=${offset}&take=${50}&fromStore=${
        fromStore ? fromStore : ""
      }&toStore=${toStore ? toStore : ""}&fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )} 00:00:00&toDate=${moment(toDate).format(
        "YYYY-MM-DD"
      )} 23:59:59&search=`,
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.STORE_TO_STORE,
          payload: {
            storeToStoreForExport: respData.data.stores,
          },
        });
        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.s2s} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const makeATransfers2s = (values, setOpen, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = {
      product: values.stocks.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
        };
      }),

      fromStore: +values.storeTo,
      toStore: values.store,
    };

    Utils.api.postApiCall(
      Utils.endPoints.createS2S,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "successfull");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(gets2sList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createS2S} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};
export const updateStateS2s = (id, status) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.putApiCall(
      Utils.endPoints.updateStatusS2S + id + "?status=" + status,
      // dataToSend,{}
      {},
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "Success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(gets2sList());
        // resetForm({});
        // setOpen(null);
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateStatusS2S} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const getPdfs2s = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    axios(`${Utils.constants.baseUrl}s2s-stocks/pdf/${id}`, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window

        // window.open(fileURL);
        // const linkSource = `data:application/pdf;base64,${base64data}`;
        const downloadLink = document.createElement("a");
        const fileName = `tns${id}.pdf`;
        downloadLink.href = fileURL;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.s2sPdf} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      });
  };
  // return (dispatch, getState) => {
  //   const { order, sortField, offset, take, search } =
  //     getState().warehouseToStoreReducer;
  //   dispatch({
  //     type: Utils.ActionName.LOADING,
  //     payload: { loading: true },
  //   });
  //   Utils.api.getApiCall(
  //     Utils.endPoints.s2sPdf + id,
  //     "",
  //     // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
  //     (respData) => {
  //       // const blobToBase64 = (blob) => {
  //       //   const reader = new FileReader();
  //       //   reader.readAsDataURL(blob);
  //       //   return new Promise((resolve) => {
  //       //     reader.onloadend = () => {
  //       //       resolve(reader.result);
  //       //     };
  //       //   });
  //       // };
  //       // blobToBase64(respData?.data)
  //       //   .then((res) => {
  //       //     // do what you wanna do
  //       //     console.log(res, "shahsnak"); // res is base64 now
  //       //   })
  //       //   .catch(() => {
  //       //     debugger;
  //       //   });
  //       // var reader = new FileReader();
  //       // reader.onload = function () {
  //       //   var dataUrl = reader.result;
  //       //   var base64 = dataUrl.split(",")[1];
  //       //   // callback(base64);
  //       //   console.log("shashank")
  //       // };
  //       // reader.readAsDataURL(respData?.data);
  //       fs.readFileSync
  //       debugger;
  //       const url = window.URL.createObjectURL(new Blob([respData?.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "file.pdf"); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //       // var reader = new FileReader();
  //       // reader.readAsDataURL(respData?.data);
  //       // reader.onloadend = function () {
  //       //   debugger;
  //       //   var base64data = reader.result;
  //       //   console.log(base64data, "base64data");
  //       //   const linkSource = `data:application/pdf;base64,${base64data}`;
  //       //   const downloadLink = document.createElement("a");
  //       //   const fileName = `tns${id}.pdf`;
  //       //   downloadLink.href = linkSource;
  //       //   downloadLink.download = fileName;
  //       //   downloadLink.click();
  //       // };
  //       dispatch({
  //         type: Utils.ActionName.LOADING,
  //         payload: { loading: false },
  //       });
  //       // });

  //       // navigate("/dashboard");
  //     },
  //     (error) => {
  //       dispatch({
  //         type: Utils.ActionName.LOADING,
  //         payload: { loading: false },
  //       });
  //       let { data } = error;
  //       Utils.showAlert(
  //         2,
  //         `${Utils.endPoints.s2sPdf} GET Method error ${
  //           JSON.stringify(data?.message) || data?.error
  //         }`
  //       );
  //       // Utils.showAlert(2, data?.message?.join(",") || data?.error);
  //       // setSubmitting(true);
  //     }
  //   );
  // };
};

export const updateQuntity = (values, setOpen, id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.updateQuantityS2s + id + `?quantity=${values?.quantity}`,
      {},
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "successfull");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(gets2sList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.updateQuantityS2s} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
