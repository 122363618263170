import moment from "moment";
import Utils from "../../../utility";
export const getAllOrders = () => {
  return (dispatch, getState) => {
    // dispatch({
    //   type: Utils.ActionName.LOADING,
    //   payload: { loading: true },
    // });
    const {
      order,
      sortField,
      offset,
      take,
      search,
      fromDate,
      toDate,
      storeId,
    } = getState().onlineOrdersReducer;

    Utils.api.getApiCall(
      Utils.endPoints.orders,
      `?order=${order}&take=${take}&sortField=${sortField}&offset=${offset}&search=${search}&orderType=tns-app${
        fromDate
          ? "&fromDate=" + moment(fromDate).format("YYYY-MM-DD") + " 00:00:00"
          : ""
      }${
        toDate
          ? "&toDate=" + moment(toDate).format("YYYY-MM-DD") + " 23:59:59"
          : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.ONLINE_ORDERS,
          payload: {
            ordersList: respData.data?.order,
            // count: respData?.data?.count,
          },
        });
        // if (respData.data?.order?.length)
        //   respData.data?.order?.forEach((item) => {
        //     if (item?.zomato) {
        //       dispatch(trackStatusOfOrders(item?.zomato?.internalOrderID));
        //     }
        //   });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orders} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getAllOrdersTimeInterval = (callBack) => {
  return (dispatch, getState) => {
    // dispatch({
    //   type: Utils.ActionName.LOADING,
    //   payload: { loading: true },
    // });
    const {
      order,
      sortField,
      offset,
      take,
      count,
      search,
      fromDate,
      toDate,
      ordersList,
      storeId,
    } = getState().onlineOrdersReducer;
    Utils.api.getApiCall(
      Utils.endPoints.orders,
      `?order=${order}&take=${take}&sortField=${sortField}&offset=${offset}&search=${search}&orderType=tns-app${
        fromDate
          ? "&fromDate=" + moment(fromDate).format("YYYY-MM-DD") + " 00:00:00"
          : ""
      }${
        toDate
          ? "&toDate=" + moment(toDate).format("YYYY-MM-DD") + " 23:59:59"
          : ""
      }&store=${storeId || ""}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.ONLINE_ORDERS,
          payload: {
            ordersList: respData.data?.order,
            // count: respData?.data?.count,
          },
        });
        callBack(respData?.data?.order[0]?.id, ordersList[0]?.id);

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        // dispatch({
        //   type: Utils.ActionName.LOADING,
        //   payload: { loading: false },
        // });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orders} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**track status */

export const trackStatusOfOrders = (internal_order_id, index) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const {
      ordersListStatus,
      //   order,
      //   sortField,
      //   offset,
      //   take,
      //   count,
      //   search,
      //   fromDate,
      //   toDate,
      ordersList,
    } = getState().onlineOrdersReducer;
    Utils.api.postApiCall(
      "https://uatservices.thenewshop.in/api/v1/zomato/track",
      {
        internal_order_id,
      },
      (respData) => {
        // console.log(ordersListStatus, "ordersListStatus");
        if (respData.data?.status?.code == 200) {
          // ordersList[index].orderStatus = respData.data?.order_status;
          dispatch({
            type: Utils.ActionName.ONLINE_ORDERS,
            payload: {
              ordersListStatus: [...ordersListStatus, respData.data],
              zomatoData: respData.data,
              // count: respData?.data?.count,
            },
          });
        }
        // callBack(respData?.data?.order[0]?.id, ordersList[0]?.id);

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orders} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const creatOrder = (values, products, totalAmount, open) => {
  return (dispatch) => {
    const { email, password } = values;
    let dataToSend = {};
    dataToSend = {
      product: products.map((item) => {
        return {
          product: item.id,
          quantity: item.quantity,
          printedMrp: item.printedMrp,
          sellingPrice: item.sellingPrice,
          productCostPrice: item.productCostPrice,
          expiryDate: item.expiryDate,
        };
      }),
      paymentMethod: values.payType ? values.payType : open,
      customerPhoneNumber: values.phoneNumber,
      store: 1,
      // age:10,
      age: values.ageGroup ? values.ageGroup : "",
      notes: "",
      gender: values.gender ? values.gender : "",
      // fromAddress: 'string',
      orderAmountTotal: `${totalAmount}`,
    };
    Utils.api.postApiCall(
      Utils.endPoints.orderCreate,
      dataToSend,
      (respData) => {
        Utils.showAlert(2, "Success");
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orderCreate} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getRunnerList = () => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } = getState().storeReducer

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      "users?order=ASC&offset=1&take=10&sortField=userType&search=R",
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
      (respData) => {
        // dispatch({
        //   type: Utils.ActionName.LOADING,
        //   payload: { loading: false },
        // });
        dispatch({
          type: Utils.ActionName.ONLINE_ORDERS,
          payload: {
            runnerList: respData.data?.users,
          },
        });
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const updateOrderStatus = (id,storeId, status) => {
  return (dispatch, getState) => {
    let dataToSend = {};
    // const { storeId } = getState().onlineOrdersReducer;

    Utils.api.putApiCall(
      `${Utils.endPoints.orderStatusUpdate}${storeId}/` +
        id +
        `?status=${status}`,
      dataToSend,
      (respData) => {
        Utils.showAlert(1, "Success");
        dispatch(getAllOrders());
      },
      (error) => {
        let { data } = error;
        // Utils.showAlert(2, data?.message);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.orderStatusUpdate} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
      }
    );
  };
};

//updateSingleOrderStatus
export const updateSingleOrderStatus = (id, status) => {
  return (dispatch) => {
    let dataToSend = {};
    Utils.api.putApiCall(
      Utils.endPoints.orderStatusUpdate + `${id}?status=${status}`,
      dataToSend,
      (respData) => {
        Utils.showAlert(1, "Success");
        alert("Order successfully delivered. Please return to your store");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.orderStatusUpdate} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message);
      }
    );
  };
};
//orders by id
export const getOrdersById = (values) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { orderId } = values;
    const { offset, fromDate, toDate } = getState().onlineOrdersReducer;
    Utils.api.getApiCall(
      Utils.endPoints.getOrderById,
      `${orderId}?offset=${offset}&take=${10}&${
        fromDate
          ? "&fromDate=" + moment(fromDate).format("YYYY-MM-DD") + " 00:00:00"
          : ""
      }${
        toDate
          ? "&toDate=" + moment(toDate).format("YYYY-MM-DD") + " 23:59:59"
          : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.ONLINE_ORDERS,
          payload: {
            ordersList: respData.data?.order,
            // count: respData?.data?.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message);
        Utils.showAlert(
          2,
          `${Utils.endPoints.getOrderById} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const asignRunner = (id, runner) => {
  return (dispatch) => {
    let dataToSend = {
      runner,
    };

    Utils.api.putApiCall(
      Utils.endPoints.runnerAsign + id,
      dataToSend,
      (respData) => {
        // Utils.showAlert(1, 'Success')
        // setOpen(null)
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.runnerAsign} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message);
        // setSubmitting(true);
      }
    );
  };
};

export const orderInvoice = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.orderInvoice + id,
      "",
      (respData) => {
        var winparams =
          "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
          "resizable,screenX=50,screenY=50,width=850,height=1050";
        var htmlPop =
          "<embed width=100% height=100%" +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>';
        var printWindow = window.open("", "PDF", winparams);
        //@ts-ignore
        printWindow.document.write(htmlPop);
        //@ts-ignore
        // printWindow.print()

        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(1, "successfully");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.orderInvoice} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.error || data?.message);
      }
    );
  };
};

/***
 * update razor token
 */

export const updateRazorPayToken = (orderId, token, resetState) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      Utils.endPoints.updateRazorToken +
        `?orderId=${orderId}&paymentToken=${token}`,
      {},
      (respData) => {
        Utils.showAlert(1, "Success");
        dispatch(getAllOrders());
        resetState();
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateRazorToken} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const cancelOrder = (id) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      Utils.endPoints.cancelOrder + `${id}`,
      {},
      (respData) => {
        Utils.showAlert(1, "Success");
        dispatch(getAllOrders());
        // resetState();
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.cancelOrder} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const zomatoUpdate = (
  orderId,
  storeID,
  deliveryMode,
  fromDate,
  toDate
) => {
  return (dispatch) => {
    let dataToSend = {};
    dataToSend = {
      deliveryMode: deliveryMode,
    };
    Utils.api.postApiCall(
      Utils.endPoints.orders + `/delivery/${orderId}`,
      dataToSend,
      () => {
        Utils.showAlert(
          1,
          `${
            deliveryMode === "store"
              ? "Store Driver Assigned"
              : "Zomato Driver Assigned"
          }`
        );
        dispatch(getAllOrders(storeID, fromDate, toDate));
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(2, data?.message);
      }
    );
  };
};
