import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
// import { NavList } from "../../constants";
import { Box, ListItemIcon, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useNavigate } from "react-router-dom";
import "./sidebar.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import InventoryIcon from "@mui/icons-material/Inventory";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ArticleIcon from "@mui/icons-material/Article";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useLocation } from "react-router-dom";
// import { logout } from "../../screens/onboarding/login/action";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { logout } from "../../pages/auth/action";
function hasChildren(item) {
  const { items: children } = item;
  //   const location = useLocation();

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

const navAttribute = {
  disableGutters: true,
};
const NavList = [
  {
    icon: <SupervisorAccountIcon />,
    title: "Super Admin",
    key: "superAdmin",
    id: "superAdmin",
    items: [
      {
        title: "App Version",
        to: "/app-version",
        id: "appVersion",
      },
      {
        title: "Banner",
        to: "/banner",
        id: "banner",
      },
      {
        title: "Online Order",
        to: "/online-order",
        id: "onlineOrder",
      },
    ],
  },
  // {
  //   icon: <ReceiptIcon />,
  //   title: "Invoices/Billings",
  //   key: "Invoices/Billings",
  //   id: "invoiceBilling",

  //   items: [
  //     {
  //       id: "order",
  //       title: "Order",
  //       to: "/order",
  //     },
  //     {
  //       id: "return",
  //       title: "Return",
  //       to: "/order-return",
  //     },
  //   ],
  // },
  {
    icon: <DashboardIcon />,
    title: "DashBoard (beta)",
    key: "dashboard",
    id: "dashboard",
    to: "/dashboard",
    items: [
      // {
      //   id: "dashboard",
      //   title: "Dashboard",
      //   to: "/dashboard",
      // },
      // {
      //   title: "Return",
      //   to: "/return",
      // },
    ],
  },
  {
    icon: <StorefrontIcon />,
    title: "Store Related",
    key: "storeRelated",
    id: "storesRelated",
    items: [
      {
        id: "store",
        title: "Stores",
        to: "/store",
      },
      {
        id: "royalty",
        title: "Store Royalty",
        to: "/store-royalty",
      },
      {
        id: "royaltyHistory",
        title: "Royalty History",
        to: "/royalty-history",
      },

      {
        id: "skutimeline",
        title: "SKU Timeline",
        to: "/SKU-Timeline",
      },
      // {
      //   title: "Store RTV stocks",
      //   to: "/rtvstocks",
      // },
      // {
      //   title: "Discarded store Stocks",
      //   to: "/discard-store-stocks",
      // },

      // {
      //   title: "Edit Cost & Selling  Price",
      //   to: "/edit-cost-selling-price",
      // },
    ],
  },
  {
    icon: <SummarizeIcon />,
    title: "Reports",
    key: "reportsRelated",
    id: "reportsRelated",
    items: [
      {
        id: "billingReport",
        title: "Billing Report",
        to: "/billing-report",
      },
      {
        id: "return",
        title: "Return Report",
        to: "/return-report",
      },
      {
        id: "discardRtvReport",
        title: "Discard Report",
        to: "/discard-report",
      },
      {
        id: "storeStock",
        title: "Current Stock Report",
        to: "/current-stock-report",
      },

      {
        id: "cashoutReport",
        title: "Cash Out Report",
        to: "/cash-out-report",
      },
      {
        id: "pendingCashReport",
        title: "Pending Cash Report",
        to: "/pending-cash-report",
      },
      {
        id: "storeRtvReport",
        title: "RTV Report",
        to: "/rtv-report",
      },
      {
        id: "storeGrnReport",
        title: "GRN Report",
        to: "/grn-report",
      },
      {
        id: "credit",
        title: "Credit",
        to: "/credit",
      },
      {
        id: "gstr",
        title: "GSTR (beta)",
        to: "/gstr",
      },
      {
        id: "shiftReport",
        title: "Shift Report",
        to: "/shift-report",
      },
      // {
      //   title: "Warehouse grn report",
      //   to: "/warehouse-grn-report",
      // },
      // {
      //   id: "stockLedger",
      //   title: "Ledger Reports (Beta)",
      //   to: "/stock-ledger",
      // },
    ],
  },
  // {
  //   icon: <WarehouseIcon />,
  //   title: "Warehouse Related",
  //   key: "warehouseRelated",
  //   id: "warehouseRelated",
  //   items: [
  //     {
  //       title: "Warehouses",
  //       to: "/warehouse",
  //       id: "warehouse",
  //     },
  //     {
  //       title: "Warehouse Stocks",
  //       to: "/warehouse-stock",
  //       id: "warehouseStock",
  //     },

  //     {
  //       title: "SKU Timeline warehouse",
  //       to: "/SKU-Timeline-warehouse",
  //     },
  //     {
  //       title: "RTV'd Warehouse Stocks",
  //       to: "/warehouse-rtvstocks",
  //       id: "warehouseRTV",
  //     },
  //     {
  //       title: "Discarded Warehouse Stocks",
  //       to: "/discard-warehouse-stocks",
  //       id: "warehouseDiscardRTV",
  //     },
  //   ],
  // },
  {
    icon: <ArticleIcon />,
    title: "GRN",
    id: "grnPo",
    key: "GRN",
    items: [
      {
        title: "GRN",
        to: "/grn",
        id: "grn",
      },

      {
        title: "Store PO's (beta)",
        to: "/store-po",
        id: "storePo",
      },

      // {
      //   id: "warehousePo",
      //   title: "Warehouse PO",
      //   to: "/warehouse-po",
      // },
    ],
  },
  {
    icon: <LocalShippingIcon />,
    title: "Stock Transfers",
    id: "stockTransfer",
    key: "stockTransfers",
    items: [
      // {
      //   title: "Warehouse to store ",
      //   to: "/warehouse-to-store",
      //   id: "stockTransferW2S",
      // },
      // {
      //   title: "Warehouse to warehouse",
      //   to: "/warehouse-to-warehouse",
      //   id: "stockTransferW2W",
      // },
      // {
      //   title: "Store to warehouse",
      //   to: "/store-to-warehouse",
      //   id: "stockTransferS2W",
      // },
      {
        title: "Store To Store",
        to: "/store-to-store",
        id: "stockTransferS2S",
      },
    ],
  },
  {
    icon: <SupervisedUserCircleIcon />,
    title: "User Accounts",
    key: "UserAccounts",
    id: "usersRelated",
    items: [
      {
        title: "Users",
        to: "/users",
        id: "user",
      },
      {
        title: "Customer",
        to: "/customer",
        id: "customer",
      },

      {
        title: "Brand Owner/Vendor",
        to: "/brand-owner",
        id: "brandOwner",
      },

      {
        title: "Property Owners",
        to: "/property-owner",
        id: "propertyOwner",
      },
    ],
  },
  {
    icon: <InventoryIcon />,
    title: "Product Related",
    id: "productRelated",
    key: "productRelated",
    items: [
      {
        id: "product",
        title: "Products",
        to: "/products",
      },
      {
        title: "Product Categories",
        to: "/category",
        id: "productCategory",
      },
      {
        title: "Brands",
        to: "/brands",
        id: "brand",
      },
      {
        id: "recipes",
        title: "Recipes",
        to: "/recipes",
      },
    ],
  },
  {
    to: "/login",
    icon: <LogoutIcon />,
    title: "Logout",
    id: "logout",
  },
];
const MenuItem = (props) => {
  const { item } = props;

  // console.log(item, "THIS IS ITEM");
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = (props) => {
  const dispatch = useDispatch();
  const { item } = props;
  const Navigate = useNavigate();
  const { permissions } = useSelector((state) => state.commonReducer);
  const location = useLocation();
  //   const dispatch = useDispatch();
  return (
    <Box
      className="navi_dp"
      sx={
        localStorage.getItem("userType") &&
        localStorage.getItem("userType") === "SA"
          ? {}
          : permissions && item?.id && permissions[item?.id]?.view
          ? {}
          : item?.id === "logout"
          ? {}
          : { display: "none" }
      }
    >
      <ListItem {...navAttribute}>
        <Link
          to={item.to}
          className={`nvBx ${location?.pathname == item.to ? "active" : ""}`}
          sx={location?.pathname == item.to ? { background: "black" } : {}}
          onClick={(e) => {
            if (item?.title == "Logout") {
              e.preventDefault();
              dispatch(logout(Navigate));
            }
          }}
        >
          {item?.icon ? (
            <ListItemIcon>
              {/* <img src={item.icon} alt="" /> */}
              {item.icon}
            </ListItemIcon>
          ) : (
            <></>
          )}
          <Typography variant="subtitle2">{item?.title}</Typography>
        </Link>
      </ListItem>
    </Box>
  );
};

const MultiLevel = (props) => {
  const { permissions } = useSelector((state) => state.commonReducer);
  const { item } = props;
  const location = useLocation();
  const { items: children } = item;

  const [open, setOpen] = useState(
    item?.routes
      ? item.routes.includes(
          location?.pathname?.split("/").filter((x) => x != "")[0]
        )
      : false
  );

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <Box
      className="prt_drop"
      sx={
        localStorage.getItem("userType") &&
        localStorage.getItem("userType") === "SA"
          ? {}
          : permissions && item?.id && permissions[item?.id]?.view
          ? {}
          : // : children.map((x) => permissions[x?.id]?.view).includes(true)
            // ? {}
            { display: "none" }
      }
    >
      <ListItem {...navAttribute} onClick={handleClick}>
        <Box className="nvBx">
          {item?.icon ? (
            <ListItemIcon>
              {/* <img src={item.icon} alt="" /> */}
              {item.icon}
            </ListItemIcon>
          ) : (
            <></>
          )}
          <Typography variant="subtitle2">{item.title}</Typography>
          <i className="nv_icon">
            {open && true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </i>
        </Box>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="ul" className="inside_nv_list" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default function Sidebar() {
  return (
    <List className="listContainer" sx={{ bgcolor: "black", color: "#ffde59" }}>
      {NavList.map((item, index) => (
        <MenuItem key={index} item={item} />
      ))}
    </List>
  );
}
