// import React, { useRef, useState } from "react";
// import Button from "@mui/material/Button";
// import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from "@mui/material/IconButton";
// import { fileUpload } from "../../productRelated/products/action";
// import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
// import MenuItem from "@mui/material/MenuItem";
// // import DatePicker from "../../../component/date";
// import Schema from "../../../schema";
// import AutoComplete from "../../../component/input/autocomplete";
// import { useDispatch, useSelector } from "react-redux";
// import { Formik, Form, FieldArray } from "formik";
// import { createStoreGRN } from "./action";
// import Input from "../../../component/input";
// import { Box } from "@mui/material";
// import { getPOById } from "../po/action";
// import Select from "../../../component/select";
// import UploadImage from "../../../component/uploadImage";

// const AddUser = ({
//   setOpen,
//   isEdit,
//   storeId,
//   handlePrint,
//   grandTotal,
//   setGrandTotal,
// }) => {
//   const dispatch = useDispatch();
//   // const { productsDropDownList } = useSelector((state) => state.productsReducer);
//   const [isDisabled, setDisabled] = useState(false);
//   const initialValues = {
//     product: [],
//     invoice: isEdit?.length ? isEdit[0]?.invoice : "",
//     PONumber: null,
//     vehicle: null,
//     vendor: isEdit?.length ? isEdit[0]?.warehouseState : "",
//   };
//   const calculateGrandTotal = (values, index, currentValue, currentField) => {
//     const grandTotal = values.reduce((total, item, i) => {
//       if (i === index) {
//         if (currentField === "costPrice")
//           total = +currentValue * values[index].recievedQuantity + total;
//         else total = +currentValue * values[index].costPrice + total;
//       } else {
//         total = item.total + total;
//       }
//       console.log(total, "total");
//       return total;
//     }, 0);
//     setGrandTotal(grandTotal);
//   };
//   const {
//     // warehouseListForDropdown,
//     // storeListForDropdown,
//     brandOwnerListForDropdown,
//     productsDropDownList,
//     // brandOwnerListForDropdown,
//   } = useSelector((state) => state.commonReducer);
//   const { printGrnData } = useSelector((state) => state.storeStockReducer);
//   // const { brandOwnerListForDropdown } = useSelector((state) => state.brandOwnerReducer);

//   return (
//     <>
//       <Formik
//         enableReinitialize
//         initialValues={initialValues}
//         validationSchema={Schema.storeStockSchema}
//         onSubmit={(values, { setSubmitting, resetForm }) => {
//           dispatch(
//             createStoreGRN(values, setOpen, storeId, handlePrint, setSubmitting)
//           );
//         }}
//       >
//         {({
//           values,
//           errors,
//           touched,
//           setFieldValue,
//           handleChange,
//           handleBlur,
//           handleSubmit,
//           isSubmitting,
//         }) => (
//           <Form onSubmit={handleSubmit}>
//             {console.log(values, "valuesvalues")}
//             <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
//               {/* <Select
//                 handleChange={handleChange}
//                 value={values.vendor}
//                 label={"vendor Id"}
//                 option={brandOwnerListForDropdown.map((item) => (
//                   <MenuItem key={item.id} value={item?.id}>
//                     {item?.user?.name}
//                   </MenuItem>
//                 ))}
//                 name="vendor"
//                 error={errors.vendor}
//               /> */}
//               <AutoComplete
//                 freeSolo={false}
//                 // handleChange={handleChange}
//                 value={values.vendor}
//                 onChange={(event, value) => {
//                   if (value !== null) {
//                     const pIndex = brandOwnerListForDropdown.findIndex(
//                       (x) => x.id === value.id
//                     );
//                     setFieldValue(`vendor`, value?.id);
//                   }
//                 }}
//                 key={values?.vendor}
//                 id={values?.vendor}
//                 helperText={touched?.vendor && errors?.vendor}
//                 error={Boolean(touched?.vendor && errors?.vendor)}
//                 name={`vendor`}
//                 label={"Vender"}
//                 // touched={touched.product}
//                 options={brandOwnerListForDropdown}
//                 getOptionLabel={(option) => `${option?.alias}`}
//                 // isRequired={true}
//                 getOptionSelected={(option, value) => {
//                   return `${option.id}` === `${value.id}`;
//                 }}
//                 defaultValue={() => {
//                   const pIndex = brandOwnerListForDropdown.findIndex(
//                     (x) => x?.id == values.vendor
//                   );
//                   return brandOwnerListForDropdown[pIndex];
//                 }}
//               />
//               <UploadImage
//                 handleChange={
//                   (e) =>
//                     dispatch(
//                       fileUpload(e.target.files[0], (res) => {
//                         setFieldValue("invoice", res.Location);
//                       })
//                     )
//                   // handleChanegImage(e, setFieldValue, "invoice")
//                 }
//                 lable="Invoice"
//                 image={values?.invoice}
//                 id="invoice"
//                 onDelete={() => setFieldValue("invoice", "")}
//                 errors={errors?.invoice}
//                 // onEdit = {}
//               />
//               {/* <p>{}</p> */}
//               {/*
//             <Input
//               value={values.invoice}
//               name="invoice"
//               type="text"
//               error={errors.invoice}
//               label="Invoice"
//               onChange={handleChange}
//               handleBlur={handleBlur}
//             /> */}
//               <Input
//                 value={values.PONumber}
//                 name="PONumber"
//                 type="text"
//                 error={errors.PONumber}
//                 label="PO Number"
//                 onChange={(e) => {
//                   setFieldValue("PONumber", e.target.value);
//                   // setFieldValue("", e.target.value);
//                 }}
//                 handleBlur={() => {
//                   dispatch(
//                     getPOById(values.PONumber, storeId, (res) => {
//                       if (res.store.id !== storeId) {
//                         Utils.showAlert(
//                           2,
//                           "This PO is not aplicable for this store"
//                         );
//                         return;
//                       }
//                       setDisabled(true);
//                       setFieldValue(
//                         "product",
//                         res.poProducts.map((item) => {
//                           return {
//                             product: item.product.id,
//                             invoiceQuantity: item.requiredQuantity,
//                             recievedQuantity: 0,
//                             // mrp: item?.product?.productMrp,
//                             total: 0,
//                             costPrice: item?.productCostPrice,
//                             sellingPrice: item?.productSellingPrice,
//                             batch: "",
//                           };
//                         })
//                       );

//                       setFieldValue("vendor", res?.vendor?.id);
//                     })
//                   );
//                 }}
//               />
//               <Input
//                 value={values.vehicle}
//                 name="vehicle"
//                 type="text"
//                 error={errors.vehicle}
//                 label="vehicle"
//                 onChange={handleChange}
//                 handleBlur={handleBlur}
//               />
//             </Box>
//             {/* <h1 onClick={handlePrint}>ni</h1> */}
//             <FieldArray
//               name="product"
//               render={(arrayHelpers) => {
//                 console.log(errors);
//                 return (
//                   <div>
//                     {values?.product && values?.product.length > 0
//                       ? values.product.map((val, index) => (
//                           <Box sx={{ display: "flex", mb: 2 }} key={index}>
//                             <AutoComplete
//                               freeSolo={false}
//                               values={values.product[index].product}
//                               onChange={(event, value) => {
//                                 if (value !== null) {
//                                   const pIndex = productsDropDownList.findIndex(
//                                     (x) => x.id === value.id
//                                   );
//                                   setFieldValue(
//                                     `product.${index}.product`,
//                                     value?.id
//                                   );
//                                 }
//                               }}
//                               helperText={
//                                 touched?.product &&
//                                 errors?.product &&
//                                 errors?.product[index]?.product
//                               }
//                               error={Boolean(
//                                 touched?.product &&
//                                   errors?.product &&
//                                   errors?.product[index]?.product
//                               )}
//                               name={`product.${index}.product`}
//                               label={"Product Id"}
//                               // touched={touched.product}
//                               options={productsDropDownList}
//                               getOptionLabel={(option) =>
//                                 `${option?.barcode} | ${option.name}`
//                               }
//                               // isRequired={true}
//                               getOptionSelected={(option, value) => {
//                                 return `${option.id}` === `${value?.id}`;
//                               }}
//                               defaultValue={() => {
//                                 const pIndex = productsDropDownList.findIndex(
//                                   (x) => x?.id == values.product[index].product
//                                 );
//                                 return productsDropDownList[pIndex];
//                               }}
//                               isDisabled={isDisabled}
//                             />
//                             <Input
//                               value={values.product[index].invoiceQuantity}
//                               name={`product.${index}.invoiceQuantity`}
//                               type="number"
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.invoiceQuantity
//                               }
//                               // error={errors.product}
//                               label="Inv. Quantity"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                               disabled={isDisabled}
//                             />
//                             <Input
//                               value={values.product[index].recievedQuantity}
//                               name={`product.${index}.recievedQuantity`}
//                               type="number"
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.recievedQuantity
//                               }
//                               label="Recd. Quantity"
//                               onChange={(e) => {
//                                 if (
//                                   e.target.value >
//                                   values.product[index].invoiceQuantity
//                                 ) {
//                                   return;
//                                 }
//                                 handleChange(e);

//                                 setFieldValue(
//                                   `product.${index}.total`,
//                                   +e.target.value *
//                                     values.product[index].costPrice
//                                 );
//                                 calculateGrandTotal(
//                                   values.product,
//                                   index,
//                                   e.target.value,
//                                   "recievedQuantity"
//                                 );
//                               }}
//                               handleBlur={handleBlur}
//                             />
//                             {/* <Input
//                               value={values.product[index].mrp}
//                               name={`product.${index}.mrp`}
//                               type="number"
//                               // error={errors.product}
//                               error={
//                                 errors.product && errors.product[index]?.mrp
//                               }
//                               label="mrp"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                             /> */}
//                             <Input
//                               value={values.product[index].sellingPrice}
//                               name={`product.${index}.sellingPrice`}
//                               type="number"
//                               // disabled={true}
//                               // disabled={isDisabled}
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.sellingPrice
//                               }
//                               // error={errors.product}
//                               label="sellingPrice"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                             />
//                             {/* <DatePicker
//                             value={values.product[index].batch}
//                             name={`product.${index}.batch`}
//                             handleChange={(newVal) =>
//                               setFieldValue(`product.${index}.batch`, newVal)
//                             }
//                             label="Batch"
//                             error={
//                               errors.product && errors.product[index]?.batch
//                             }
//                           /> */}
//                             <Input
//                               value={values.product[index].costPrice}
//                               name={`product.${index}.costPrice`}
//                               type="number"
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.costPrice
//                               }
//                               // error={errors.product}
//                               label="costPrice"
//                               onChange={(e) => {
//                                 handleChange(e);
//                                 setFieldValue(
//                                   `product.${index}.total`,
//                                   +e.target.value *
//                                     values.product[index].recievedQuantity
//                                 );

//                                 calculateGrandTotal(
//                                   values.product,
//                                   index,
//                                   e.target.value,
//                                   "costPrice"
//                                 );
//                               }}
//                               handleBlur={handleBlur}
//                               // disabled={isDisabled}
//                             />
//                             <Input
//                               value={values.product[index].total}
//                               name={`product.${index}.total`}
//                               type="number"
//                               disabled={true}
//                               error={
//                                 errors.product && errors.product[index]?.total
//                               }
//                               label="Total"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                             />
//                             {!isDisabled && (
//                               <IconButton
//                                 style={{
//                                   background: "rgb(231 112 112)",
//                                   color: "white",
//                                   width: 35,
//                                   height: 35,
//                                   borderRadius: 5,
//                                 }}
//                                 aria-label="delete"
//                                 size="small"
//                                 onClick={() => arrayHelpers.remove(index)}
//                               >
//                                 <DeleteIcon fontSize="inherit" />
//                               </IconButton>
//                             )}
//                           </Box>
//                         ))
//                       : null}
//                     <Box sx={{ display: "flex", mb: 2 }}>
//                       <Box
//                         sx={{
//                           width: "100%",
//                           pr: 2,
//                           maxWidth: "100%",
//                           textAlign: "right",
//                         }}
//                       >
//                         Grand Total
//                       </Box>

//                       <Input
//                         value={grandTotal}
//                         disabled
//                         name="grandTotal"
//                         type="number"
//                         // error={errors.batch}
//                         label="Grand Total "
//                       />

//                       {!isDisabled && (
//                         <IconButton
//                           style={{
//                             background: "green",
//                             color: "white",
//                             width: 35,
//                             height: 35,
//                             borderRadius: 5,
//                           }}
//                           aria-label="delete"
//                           size="small"
//                           onClick={() =>
//                             arrayHelpers.push({
//                               product: null,
//                               invoiceQuantity: null,
//                               recievedQuantity: null,
//                               // mrp: null,
//                               total: 0,
//                               // landingPrice: null,
//                               costPrice: 0,
//                               sellingPrice: 0,
//                               batch: "",
//                             })
//                           }
//                         >
//                           <AddBoxTwoToneIcon fontSize="inherit" />
//                         </IconButton>
//                       )}
//                     </Box>
//                   </div>
//                 );
//               }}
//             />

//             <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
//               <Button
//                 variant="outlined"
//                 type="submit"
//                 isDisabled={isSubmitting}
//               >
//                 {isEdit?.length ? "update GRN" : "Add GRN"}
//               </Button>
//               <Button
//                 variant="outlined"
//                 onClick={() => setOpen(false)}
//                 style={{
//                   border: "1px solid #e13030",
//                   color: "#e13030",
//                   marginLeft: 10,
//                 }}
//               >
//                 Cancel
//               </Button>
//             </Box>
//           </Form>
//         )}
//       </Formik>
//     </>
//     //  </Authlayout>
//   );
// };

// export default AddUser;

import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useReactToPrint } from "react-to-print";
import SyncIcon from "@mui/icons-material/Sync";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import Modal from "../../../component/modal";
// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray, FastField } from "formik";
import { createStoreGRN, getStoreStockList } from "./action";
import Input from "../../../component/input";
import { Box, Grid, Typography } from "@mui/material";
import { getPOById } from "../po/action";
import Utils from "../../../utility";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import NormalButton from "../../../component/button";
import SearchInput from "../../../component/searchInput";

const AddUser = ({
  setOpen,
  isEdit,
  // storeId,
  // handlePrint,
  // grandTotal,
  // setGrandTotal,
}) => {
  const navigate = useNavigate();
  const Location = useLocation();
  // debugger;
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [grnConfirmation, setGrnConfirmation] = useState(false);
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const [isDisabled, setDisabled] = useState(false);
  const { loading } = useSelector((state) => state.loaderReducer);
  const [grandTotal, setGrandTotal] = useState(0);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const initialValues = {
    product: [],
    invoice: "",
    poNumber: null,
    invoiceNumber: null,
    vendor: "",
    storeId: Location?.state?.storeId || "",
  };
  const calculateGrandTotal = (values, index, currentValue, currentField) => {
    // debugger;
    const grandTotal = values.reduce((total, item, i) => {
      if (i === index) {
        if (currentField === "costPrice")
          total = +currentValue * +values[index]?.recievedQuantity + total;
        else total = +currentValue * +values[index]?.costPrice + total;
      } else {
        total = +item.total + +total;
      }
      return total;
    }, 0);
    setGrandTotal(grandTotal);
  };
  const { printGrnData, grandTotalAddedGRN } = useSelector(
    (state) => state.storeStockReducer
  );

  const {
    // warehouseListForDropdown,
    storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  // const { brandOwnerListForDropdown } = useSelector((state) => state.brandOwnerReducer);
  useEffect(() => {
    return () => {
      dispatch({
        type: Utils.ActionName.STORE_STOCK,
        payload: {
          search: "",
        },
      });
    };
  }, []);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.storeStockSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // setSubmitting(true);
          if (loading) return;
          dispatch(
            createStoreGRN(
              values,
              navigate,
              // storeId,
              handlePrint,
              setSubmitting
            )
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldError,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
              <Grid item xs={12} sm={6} lg={3}>
                <AutoComplete
                  freeSolo={false}
                  // onBlur={handleBlur}
                  values={values.storeId}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setFieldValue(`storeId`, value?.id);
                      // dispatch(CreditByStore(value?.id));
                    }
                  }}
                  name={"storeId"}
                  label={"Store Name"}
                  options={storeListForDropdown}
                  getOptionLabel={(option) => option?.storename}
                  // isRequired={true}
                  getOptionSelected={(option, value) => {
                    return `${option.id}` === `${value.id}`;
                  }}
                  defaultValue={() => {
                    const index = storeListForDropdown?.findIndex(
                      (x) => x?.id == values.storeId
                    );
                    return storeListForDropdown[index];
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <AutoComplete
                  freeSolo={false}
                  // handleChange={handleChange}
                  value={values.vendor}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setFieldValue(`vendor`, value?.id);
                    }
                  }}
                  key={values?.vendor}
                  id={values?.vendor}
                  helperText={touched?.vendor && errors?.vendor}
                  error={Boolean(touched?.vendor && errors?.vendor)}
                  name={`vendor`}
                  label={"Vendor"}
                  // touched={touched.product}
                  options={brandOwnerListForDropdown || []}
                  getOptionLabel={(option) =>
                    `${option?.alias} | ${option?.GST} `
                  }
                  // isRequired={true}
                  getOptionSelected={(option, value) => {
                    return `${option.id}` === `${value.id}`;
                  }}
                  defaultValue={() => {
                    const pIndex = brandOwnerListForDropdown.findIndex(
                      (x) => x?.id == values.vendor
                    );
                    return brandOwnerListForDropdown[pIndex];
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Input
                  value={values.invoiceNumber}
                  name="invoiceNumber"
                  type="text"
                  error={errors.invoiceNumber}
                  label="Invoice number"
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Input
                  value={values.poNumber}
                  name="poNumber"
                  type="text"
                  error={errors.poNumber}
                  label="Po Number"
                  onChange={handleChange}
                  disabled={!values?.storeId}
                  handleBlur={(e) => {
                    handleBlur(e);
                    if (!values.poNumber) return;
                    dispatch(
                      getPOById(values.poNumber, values.storeId, (res) => {
                        if (res.store.id !== values.storeId) {
                          Utils.showAlert(
                            2,
                            "This PO is not aplicable for this store"
                          );
                          return;
                        }
                        setDisabled(true);
                        setFieldValue(
                          "product",
                          res.poProducts.map((item) => {
                            return {
                              product: item.product.id,
                              barcode: item?.product?.barcode,
                              invoiceQuantity: item.requiredQuantity,
                              recievedQuantity: 0,
                              // mrp: item?.product?.productMrp,
                              total: 0,
                              costPrice: item?.productCostPrice,
                              sellingPrice: item?.productSellingPrice,
                              batch: "",
                            };
                          })
                        );

                        setFieldValue("vendor", res?.vendor?.id);
                      })
                    );
                  }}
                />
              </Grid>
            </Grid>
            {values?.product?.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "30px",
                }}
              >
                <SearchInput
                  value={search}
                  placeholder="Search by barcode..."
                  className="w-300 m-t-20"
                  handleSearch={(e) => {
                    setSearch(e.target.value);
                    if (!e.target.value?.trim()) {
                      const filteredProduct = values?.product?.map((item) => {
                        return { ...item, display: "block" };
                      });
                      setFieldValue("product", filteredProduct);
                    } else {
                      const filteredProduct = values?.product.map((item) => {
                        if (+item?.barcode?.includes(e.target.value?.trim())) {
                          return item;
                        } else return { ...item, display: "none" };
                      });
                      setFieldValue("product", filteredProduct);
                    }
                    debugger;
                    // debugger;
                  }}
                  clearSearch={() => {
                    const filteredProduct = values?.product?.map((item) => {
                      return { ...item, display: "block" };
                    });
                    setFieldValue("product", filteredProduct);
                    setSearch("");
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            {console.log(values, "shashank")}
            {/*  <TextField
                id="standard-search"
                label="Search using barcode"
                type="search"
                variant="standard"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (!e.target.value) {
                    let newTotal = 0;
                    const nwPoProduct = values.poProduct.map((item) => {
                      if (
                        values?.product &&
                        item.barcode == values?.product[0]?.barcode
                      ) {
                        newTotal = newTotal + values?.product[0]?.total;
                        return values.product[0];
                      } else {
                        newTotal = newTotal + item.total;
                        return item;
                      }
                    });
                    setGrandTotal(newTotal);
                    setFieldValue("product", nwPoProduct);
                    // calculateGrandTotal(
                    //   nwPoProduct,
                    //   index,
                    //   e.target.value,
                    //   "recievedQuantity"
                    // );
                    setFieldValue("poProduct", nwPoProduct);
                    return;
                  }
                  const filteredProduct = values?.poProduct.filter(
                    (item) => item.barcode == e.target.value?.trim()
                  );
                  setFieldValue("product", filteredProduct);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div> */}
            {/* <h1 onClick={handlePrint}>ni</h1> */}
            <>
              <FieldArray
                name="product"
                render={(arrayHelpers) => {
                  return (
                    <>
                      <div>
                        {values?.product && values?.product?.length > 0
                          ? values.product.map((val, index) => (
                              <Box
                                // key={`${
                                //   `${val?.barcode}` + index + `${search}`
                                // }`}
                                sx={{ display: val?.display }}
                                // key={`${search}`}
                              >
                                {/* {console.log(Date.now(), "key")} */}
                                <hr className="m-t-20" />
                                <Grid
                                  container
                                  rowSpacing={3}
                                  columnSpacing={2}
                                  className="m-t-5"
                                  // key={`${
                                  //   `${val?.barcode}` + index
                                  // } +${search}`}
                                >
                                  <Grid
                                    item
                                    xs={10}
                                    sm={5}
                                    lg={3}
                                    // key={`${
                                    //   `${val?.barcode}` + index
                                    // }++${search}`}
                                  >
                                    <FastField
                                      name={`product.${index}.product`}
                                      // key={`${
                                      //   `${val?.barcode}` + index + search
                                      // }`}
                                      // key={Date.now()}
                                      key={val.product ? val.product : ""}
                                    >
                                      {({ form, field }) => (
                                        <React.Fragment
                                        // key={
                                        //   val.product
                                        //     ? val.product
                                        //     : Date.now()
                                        // }
                                        >
                                          <AutoComplete
                                            // key={`${
                                            //   `${val?.barcode}` + `${index}`
                                            // }++${search}`}
                                            // key={`${index}`}
                                            freeSolo={false}
                                            values={val.product}
                                            onChange={(event, value) => {
                                              if (value !== null) {
                                                dispatch({
                                                  type: Utils.ActionName
                                                    .STORE_STOCK,
                                                  payload: {
                                                    search: value.barcode,
                                                  },
                                                });

                                                setFieldValue(
                                                  `product.${index}.recievedQuantity`,
                                                  null
                                                );
                                                setFieldValue(
                                                  `product.${index}.product`,
                                                  value?.id
                                                );
                                                // debugger;
                                                setFieldValue(
                                                  `product.${index}.barcode`,
                                                  value?.barcode
                                                );
                                                dispatch(
                                                  getStoreStockList(
                                                    values?.storeId,
                                                    (data) => {
                                                      if (data?.sellingPrice)
                                                        setFieldValue(
                                                          `product.${index}.sellingPrice`,
                                                          data?.sellingPrice
                                                        );
                                                      if (
                                                        data?.productCostPrice
                                                      )
                                                        setFieldValue(
                                                          `product.${index}.costPrice`,
                                                          data?.productCostPrice
                                                        );
                                                    }
                                                  )
                                                );
                                              }
                                            }}
                                            helperText={
                                              touched?.product &&
                                              errors?.product &&
                                              errors?.product[index]?.product
                                            }
                                            error={Boolean(
                                              touched?.product &&
                                                errors?.product &&
                                                errors?.product[index]?.product
                                            )}
                                            id={`product.${index}.product`}
                                            name={`product.${index}.product`}
                                            label={"Product Name"}
                                            // touched={touched.product}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                            }}
                                            options={productsDropDownList.filter(
                                              (item) =>
                                                !values.product
                                                  .map((x) => x.product)
                                                  .includes(item?.id)
                                            )}
                                            getOptionLabel={(option) =>
                                              `${option?.barcode} | ${option.name}`
                                            }
                                            // isRequired={true}
                                            getOptionSelected={(
                                              option,
                                              value
                                            ) => {
                                              return (
                                                `${option.id}` == `${value?.id}`
                                              );
                                            }}
                                            defaultValue={() => {
                                              const pIndex =
                                                productsDropDownList.findIndex(
                                                  (x) =>
                                                    x?.id ==
                                                    values.product[index]
                                                      .product
                                                );
                                              // debugger;
                                              return productsDropDownList[
                                                pIndex
                                              ];
                                            }}
                                            isDisabled={isDisabled}
                                          />
                                        </React.Fragment>
                                      )}
                                    </FastField>
                                  </Grid>
                                  <Grid item xs={6} sm={3} lg={1.7}>
                                    <FastField
                                      name={`product.${index}.invoiceQuantity`}
                                      key={
                                        val.product ? val.product + index : ""
                                      }
                                    >
                                      {({ form, field, value }) => (
                                        <>
                                          {/* {console.log(
                                            // value,
                                            form?.values?.product,
                                            // field,
                                            "shashank"
                                          )} */}

                                          <Input
                                            value={
                                              values.product[index]
                                                .invoiceQuantity
                                            }
                                            name={`product.${index}.invoiceQuantity`}
                                            type="number"
                                            error={
                                              errors.product &&
                                              errors.product[index]
                                                ?.invoiceQuantity
                                            }
                                            label="PO Quantity"
                                            onChange={(e) => {
                                              // debugger;
                                              handleChange(e);
                                              // debugger;
                                              // setFieldValue(
                                              //   `product.${index}.total`,
                                              //   +e.target.value *
                                              //     values?.product[index]
                                              //       .costPrice
                                              // );
                                              // setFieldValue(
                                              //   `product.${index}.invoiceQuantity`,
                                              //   +e.target.value
                                              // );

                                              // calculateGrandTotal(
                                              //   values.product,
                                              //   index,
                                              //   e.target.value,
                                              //   "invoiceQuantity"
                                              // );
                                            }}
                                            handleBlur={handleBlur}
                                            // handleBlur={(e) => {
                                            //   // if (values.product.length !== 1) {
                                            //   handleBlur(e);
                                            //   setFieldValue(
                                            //     `product.${index}.recievedQuantity`,
                                            //     e.target.value
                                            //   );

                                            //   setFieldValue(
                                            //     `product.${index}.total`,
                                            //     +e.target.value *
                                            //       values.product[index]
                                            //         .costPrice
                                            //   );
                                            //   calculateGrandTotal(
                                            //     values.product,
                                            //     index,
                                            //     e.target.value,
                                            //     "recievedQuantity"
                                            //   );
                                            //   // }
                                            // }}
                                          />
                                        </>
                                      )}
                                    </FastField>
                                  </Grid>
                                  <Grid item xs={6} sm={3} lg={1.7}>
                                    <FastField
                                      name={`product.${index}.recievedQuantity`}
                                      key={
                                        val.product ? val.product + index : ""
                                      }
                                    >
                                      {({ form, field, value }) => (
                                        <>
                                          {/* {console.log(
                                            // value,
                                            form?.values?.product,
                                            // field,
                                            "shashank"
                                          )} */}

                                          <Input
                                            value={
                                              values.product[index]
                                                .recievedQuantity
                                            }
                                            name={`product.${index}.recievedQuantity`}
                                            type="number"
                                            error={
                                              errors.product &&
                                              errors.product[index]
                                                ?.recievedQuantity
                                            }
                                            label="Recd. Quantity"
                                            onChange={(e) => {
                                              // debugger;
                                              handleChange(e);
                                              // debugger;
                                              setFieldValue(
                                                `product.${index}.total`,
                                                +e.target.value *
                                                  values?.product[index]
                                                    .costPrice
                                              );
                                              // setFieldValue(
                                              //   `product.${index}.recievedQuantity`,
                                              //   +e.target.value
                                              // );

                                              calculateGrandTotal(
                                                values.product,
                                                index,
                                                e.target.value,
                                                "recievedQuantity"
                                              );
                                            }}
                                            handleBlur={handleBlur}
                                            // handleBlur={(e) => {
                                            //   // if (values.product.length !== 1) {
                                            //   handleBlur(e);
                                            //   setFieldValue(
                                            //     `product.${index}.recievedQuantity`,
                                            //     e.target.value
                                            //   );

                                            //   setFieldValue(
                                            //     `product.${index}.total`,
                                            //     +e.target.value *
                                            //       values.product[index]
                                            //         .costPrice
                                            //   );
                                            //   calculateGrandTotal(
                                            //     values.product,
                                            //     index,
                                            //     e.target.value,
                                            //     "recievedQuantity"
                                            //   );
                                            //   // }
                                            // }}
                                          />
                                        </>
                                      )}
                                    </FastField>
                                  </Grid>

                                  <Grid item xs={6} sm={3} lg={1.7}>
                                    <FastField
                                      name={`product.${index}.sellingPrice`}
                                      key={
                                        val.product
                                          ? val.product + index + 1212
                                          : ""
                                      }
                                    >
                                      {({ form, field }) => (
                                        <Input
                                          value={
                                            values.product[index].sellingPrice
                                          }
                                          name={`product.${index}.sellingPrice`}
                                          type="number"
                                          // disabled={true}
                                          // disabled={isDisabled}
                                          error={
                                            errors.product &&
                                            errors.product[index]?.sellingPrice
                                          }
                                          // error={errors.product}
                                          label="Selling Price"
                                          onChange={handleChange}
                                          handleBlur={handleBlur}
                                          // handleBlur={(e) => {
                                          // if (values.product.length !== 1) {
                                          //   setFieldValue(
                                          //     `product.${index}.sellingPrice`,
                                          //     e.target.value
                                          //   );
                                          //   handleBlur(e);
                                          //   debugger;
                                          // }
                                          // }}
                                        />
                                      )}
                                    </FastField>
                                  </Grid>
                                  <Grid item xs={6} sm={3} lg={1.7}>
                                    <FastField
                                      name={`product.${index}.costPrice`}
                                      key={
                                        val.product
                                          ? val.product +
                                            index +
                                            34345 +
                                            +loading
                                          : ""
                                      }
                                    >
                                      {({ form, field }) => (
                                        <Input
                                          value={
                                            values.product[index].costPrice
                                          }
                                          name={`product.${index}.costPrice`}
                                          type="number"
                                          error={
                                            errors.product &&
                                            errors.product[index]?.costPrice
                                          }
                                          // error={errors.product}
                                          label="Cost Price Incl Taxes"
                                          onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue(
                                              `product.${index}.total`,
                                              +e.target.value *
                                                values.product[index]
                                                  .recievedQuantity
                                            );

                                            calculateGrandTotal(
                                              values.product,
                                              index,
                                              e.target.value,
                                              "costPrice"
                                            );
                                            // debugger;
                                          }}
                                          handleBlur={handleBlur}
                                          // handleBlur={(e) => {
                                          //   // debugger;
                                          //   if (values.product.length !== 1) {
                                          //     setFieldValue(
                                          //       `product.${index}.costPrice`,
                                          //       e.target.value
                                          //     );
                                          //     // debugger;
                                          //     setFieldValue(
                                          //       `product.${index}.total`,
                                          //       +e.target.value *
                                          //         values.product[index]
                                          //           .recievedQuantity
                                          //     );
                                          //     debugger;
                                          //     handleBlur(e);
                                          //   }
                                          // }}
                                          disabled={loading}
                                        />
                                      )}
                                    </FastField>
                                  </Grid>
                                  <Grid item xs={6} sm={3} lg={1.2}>
                                    <FastField name={`product.${index}.total`}>
                                      {({ form, field }) => (
                                        <>
                                          <Input
                                            value={values.product[index].total}
                                            name={`product.${index}.total`}
                                            type="number"
                                            disabled={true}
                                            error={
                                              errors.product &&
                                              errors.product[index]?.total
                                            }
                                            label="Total"
                                            onChange={handleChange}
                                            handleBlur={handleBlur}
                                          />
                                        </>
                                      )}
                                    </FastField>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={1.5}
                                    lg={1}
                                    className="Dflex al-cnt"
                                  >
                                    {/* <button */}

                                    {/* </button> */}
                                    <SyncIcon
                                      // className="pointer"
                                      sx={{
                                        display: "block",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setGrandTotal(
                                          values.product.reduce(
                                            (val, pro, i) =>
                                              (val +=
                                                +pro?.recievedQuantity *
                                                +pro.costPrice),
                                            0
                                          )
                                        );
                                        values.product.map((item, i) => {
                                          setFieldValue(
                                            `product.${i}.total`,
                                            +item?.recievedQuantity *
                                              +item.costPrice
                                          );
                                        });
                                      }}
                                    />
                                    {!isDisabled && (
                                      <IconButton
                                        style={{
                                          background: "rgb(231 112 112)",
                                          color: "white",
                                          width: 35,
                                          height: 35,
                                          borderRadius: 5,
                                        }}
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          // debugger;
                                          setGrandTotal(
                                            values.product.reduce(
                                              (val, pro, i) =>
                                                index === i
                                                  ? val
                                                  : (val += pro?.total),
                                              0
                                            )
                                          );
                                        }}
                                      >
                                        <DeleteIcon fontSize="inherit" />
                                      </IconButton>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            ))
                          : null}
                        <hr className="m-t-20 m-b-20" />

                        <Box sx={{ display: "flex", mb: 2 }}>
                          <Box
                            sx={{
                              width: "100%",
                              pr: 2,
                              maxWidth: "100%",
                              textAlign: "right",
                              verticalAlign: "middle",
                            }}
                          >
                            {/* Grand Total */}
                          </Box>

                          <Input
                            value={grandTotal}
                            disabled
                            name="grandTotal"
                            type="number"
                            // error={errors.batch}
                            label="Grand Total "
                          />

                          {!isDisabled && (
                            <IconButton
                              style={{
                                background: "green",
                                color: "white",
                                width: 35,
                                margin: 10,
                                // display:"block",
                                height: 35,
                                borderRadius: 5,
                              }}
                              disabled={search}
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                arrayHelpers.push({
                                  product: null,
                                  invoiceQuantity: null,
                                  recievedQuantity: null,
                                  // mrp: null,
                                  barcode: null,
                                  total: 0,
                                  poNumber: "",
                                  // landingPrice: null,
                                  costPrice: 0,
                                  sellingPrice: 0,
                                  batch: "",
                                })
                              }
                            >
                              <AddBoxTwoToneIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </Box>
                      </div>
                      {/* )} */}
                    </>
                  );
                }}
              />
            </>
            <hr className="m-t-20 m-b-20" />

            <div className="Dflex al-cnt fl-end">
              <NormalButton
                className="btn-purple "
                buttonText={"Add GRN"}
                // onPress={() => {}}
                disabled={search || loading}
                type="submit"
              />
              <NormalButton
                className="btn-simple m-l-30"
                buttonText="Cancel"
                onPress={() => setGrnConfirmation(true)}
              />
            </div>
            {/* <Box
              sx={{
                display: "flex",
                mb: 2,
                justifyContent: "flex-end",
                marginBottom: "400px",
              }}
            >
              <Button
                variant="outlined"
                type="submit"
                disabled={search || loading}
              >
                {"Add GRN"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/grn")}
                style={{
                  border: "1px solid #e13030",
                  color: "#e13030",
                  marginLeft: 10,
                }}
              >
                Cancel
              </Button>
            </Box> */}
            <div
              ref={componentRef}
              style={{
                width: "180.874016px",
                margin: "10px auto",
                display: isSubmitting ? "" : "none",
                // border: "2px solid black",
              }}
            >
              <img
                src="https://thenewshop.in/header-logo.png"
                alt="The new shop"
                style={{ width: "170px" }}
              />
              <div style={{ margin: "0px 15px" }}>
                <div style={{ fontSize: 14, fontWeight: 500 }}>
                  <b>GRN Id:</b> {printGrnData?.grnId || printGrnData?.id}
                </div>
                {grandTotalAddedGRN ? (
                  <div style={{ fontSize: 14 }}>
                    <b>Total Amount:</b>
                    {grandTotalAddedGRN}
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <div style={{ fontSize: 14, fontWeight: 500 }}>
                  <b>Store :</b>
                  {(printGrnData?.grnProducts &&
                    printGrnData?.grnProducts[0]?.grn?.store?.storename) ||
                    printGrnData?.store?.storename}
                </div>
                <div style={{ fontSize: 14, fontWeight: 500 }}>
                  <b> GRN By :</b>{" "}
                  {(printGrnData?.grnProducts &&
                    printGrnData?.grnProducts[0]?.grn?.createdBy?.name) ||
                    printGrnData.createdBy?.name}
                </div>
                <div style={{ fontSize: 14, fontWeight: 500 }}>
                  <b> Date :</b>
                  {(printGrnData?.grnProducts &&
                    moment(printGrnData?.grnProducts[0]?.grn?.createdAt).format(
                      "DD-MM-YYYY  HH:mm:ss"
                    )) ||
                    moment(printGrnData?.createdAt).format(
                      "DD-MM-YYYY  HH:mm:ss"
                    )}
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 14,
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <b>Product</b>
                    </div>
                    <div>
                      <b>Quantity</b>
                    </div>
                  </div>
                  {printGrnData?.grnProducts?.length &&
                    printGrnData?.grnProducts?.map((item, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: 12,

                          // textAlign:"center",
                        }}
                      >
                        <div style={{ maxWidth: "126px" }}>
                          {item?.product?.name}
                        </div>
                        <div>{item?.recievedQuantity}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {/* {(open || openBulkUpload) && ( */}

      {/* )} */}
      <Modal
        header={`Confirmation`}
        children={
          <div>
            {/* <h2></h2> */}
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Do you want to cancel grn without submitting?
            </Typography>
            {/* <Box sx={{ display: "flex", my: 2, justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setGrnConfirmation(false);
                }}
                style={{
                  border: "1px solid #e13030",
                  color: "#e13030",
                  marginLeft: 10,
                }}
              >
                No
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  // setFileData(null);
                  setGrnConfirmation(false);
                  navigate("/grn");
                  // resetForm();
                  // setFieldValue("storeId", "1");
                }}
                style={{
                  border: "1px solid #e13030",
                  color: "#e13030",
                  marginLeft: 10,
                }}
              >
                Yes
              </Button> */}
            <hr className="m-t-20 m-b-20" />
            <div className="Dflex al-cnt fl-end">
              <NormalButton
                className="btn-simple"
                buttonText="Yes"
                onPress={() => navigate("/grn")}
              />
              <NormalButton
                className="btn-purple m-l-30"
                buttonText={"No"}
                onPress={() => {
                  setGrnConfirmation(false);
                }}
              />
            </div>
            {/* <h1 onClick={() => }>sda</h1> */}
            {/* </Box> */}
          </div>
        }
        handleClose={() => {
          setGrnConfirmation(false);
          // setIsEdit(null);
        }}
        open={grnConfirmation}
        // minWidth={"85%"}
      />
      {/* <DeleteConfirmationPopup
        title="Add GRN"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          navigate("/grn");
          // dispatch(deleteBrand(deleteConfirmation));
        }}
        open={deleteConfirmation}
        minWidth={500}
      /> */}
    </>
    //  </Authlayout>
  );
};

export default React.memo(AddUser);
